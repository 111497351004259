import type { StickyAssets } from '@sticky/components';

export const assets: StickyAssets = {
  companyLogo: '/assets/logos/logo-tgv-inoui.svg',
  enterpriseLogo: '/assets/logos/logo-sncf.svg',
  brandLogo: {
    default: '/assets/logos/brand-logo-max-actif.svg',
    FULLTIME: '/assets/logos/brand-logo-max-actif-plus.svg',
    PARTTIME: '/assets/logos/brand-logo-max-actif.svg',
  },
};
