import type { StickyColors } from '@sticky/components';

const colorFrenchBerry = '#9b2743';
const colorFrenchBerryLight = '#f2e4e7';
const colorFrenchBerryPlus = '#A6093D';
const colorFrenchBerryLess = '#ebd4d9';
const colorCarbon = '#3C3C3B';
const colorCarbonDark = '#27251f';
const colorCarbonLess = '#0e0e0c';
const colorWarmGray1 = '#E0DED8';
const colorWarmGray2 = '#C3BEB4';
const colorWhite = '#fff';
const colorOrangeDark = '#b74806';
const colorOrange = '#e9c8b4';
const colorOrangeLight = '#feece2';
const colorRedDark = '#b00020';
const colorRedLight = '#f2e4e7';
const colorRed = 'rgba(176, 0, 32, 0.3)';
const colorBlueDark = '#0075a1';
const colorBlueLight = '#e5f1f5';
const colorBlue = 'rgba(0, 117, 161, 0.3)';
const colorGreenDarker = '#338037';
const colorGreenDark = '#388e3c';
const colorGreenLight = '#d9eeda';
const colorGreen = '#4caf50';
const colorGray = '#75766B';
const colorDarkGray = '#333333';

export const colors: StickyColors = {
  bodyBackground: colorWarmGray1,
  headerBackground: colorCarbon,
  bannerBackground: colorFrenchBerry,
  headerMenuBackground: colorCarbon,
  backgroundLess: colorCarbonLess,
  disabledBackground: '#f3f2ef',
  actionIcon: colorFrenchBerry,
  illustrationIcon: colorFrenchBerry,
  outlinedButtonText: colorFrenchBerry,
  primary: colorFrenchBerry,
  primaryLight: colorFrenchBerryLight,
  primaryDark: colorFrenchBerryPlus,
  primaryLess: colorFrenchBerryLess,
  secondary: colorWarmGray1,
  textPrimary: colorCarbon,
  textPrimaryDark: colorCarbonDark,
  textContrasted: colorFrenchBerry,
  textContrastedDark: colorFrenchBerryPlus,
  textSecondaryContrasted: colorWhite,
  textSecondary: colorWhite,
  warmGray1: colorWarmGray1,
  warmGray2: colorWarmGray2,
  darkGray: colorDarkGray,
  white: colorWhite,
  black: colorCarbon,
  info: colorBlue,
  infoLight: colorBlueLight,
  infoDark: colorBlueDark,
  labelGray: colorGray,
  success: colorGreen,
  successLight: colorGreenLight,
  successDark: colorGreenDark,
  successDarker: colorGreenDarker,
  warning: colorOrange,
  warningLight: colorOrangeLight,
  warningDark: colorOrangeDark,
  error: colorRed,
  errorLight: colorRedLight,
  errorDark: colorRedDark,
  kombo: '#208cfb',
};
