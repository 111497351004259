/* eslint-disable react-hooks/exhaustive-deps */
import type { ChangeEvent, RefObject } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from '@mui/material';
import { tss } from 'tss-react/mui';

import {
  ActionButton,
  Panel,
  PanelActionButtons,
  PanelContent,
} from '@sticky/components';
import { t } from '@sticky/i18n';
import type {
  IStation,
  ISubscriptionList,
  ProductSelect,
} from '@sticky/sticky-common';
import {
  AlertMessage,
  cleanDestinationStations,
  cleanProposalDestination,
  cleanProposalDestinationStation,
  cleanProposalError,
  cleanProposalOriginStation,
  customerSelectors,
  formatApiDateUTC,
  formatYYYYMMDDMonthNameAndYearTz,
  getDestinationStationsList,
  getProposalQuoteSales,
  getStartDateValidityListTz,
  getStationsList,
  InputAutocomplete,
  RadioButtonGroup,
  RouteLink,
  scrollToId,
  setProposalDestinationStation,
  setProposalOriginStation,
  setProposalProductCode,
  setProposalProductSelect,
  setProposalStartValidity,
} from '@sticky/sticky-common';
import { cleanBasketBookingSale } from '@sticky/sticky-common/src/features/basket/store/basket';
import {
  useAppDispatch,
  useAppSelector,
} from '@sticky/sticky-common/src/hooks';

import { ModalShowElligibleStations } from './modal-show-elligible-stations';

const useStyles = tss.create(({ theme }) => {
  const {
    breakpoints,
    app: { colors },
  } = theme;

  return {
    root: {
      [breakpoints.up('breakPointDesktop')]: {
        paddingTop: '0.5rem',
      },
    },
    link: {
      color: colors.primary,
      fontWeight: 'bold',
      textDecoration: 'underline',
    },
    inputSelect: {
      '& .MuiOutlinedInput-input': {
        padding: '0.8rem 1rem',
      },
    },
  };
});

// Component
export const WidgetProposalInit = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  // Error states
  const [originSelectError, setOriginSelectError] = useState('');
  const [destinationSelectError, setDestinationSelectError] = useState('');
  const [showModalShowEligibleStations, setShowModalShowEligibleStations] =
    useState(false);
  const [startDates] = useState<string[]>(
    getStartDateValidityListTz(new Date(), 3),
  );
  const [isError, setIsError] = useState(false);
  const [errorCode, setErrorCode] = useState('');

  // Get stores
  const originStations: IStation[] = useAppSelector(
    state => state.stations?.eligibleStations ?? [],
  );
  const destinationStations: IStation[] = useAppSelector(
    state => state.stations?.destinationStations ?? [],
  );
  const proposal = useAppSelector(state => state.proposal);
  const customer = useAppSelector(state => state.customer);
  const allowedCards = useAppSelector(customerSelectors.getAllowedCards);
  const { error: errorProposal } = useAppSelector(state => state.proposal);
  const { error: errorStations } = useAppSelector(state => state.stations);

  // Build list of deals and initial value
  const subscriptions: ISubscriptionList[] = t('proposal.deals', {
    returnObjects: true,
  });

  // Handle error message
  const handleErrorMessage = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (error: any) => {
      if (error.data?.details?.[0]?.code === '3327') {
        setErrorCode('PROPOSAL_NO_TRAVEL');
      } else {
        setErrorCode('PROPOSAL_NO_SERVICE');
      }
      scrollToId('alert-error');
      dispatch(cleanProposalError());
    },
    [dispatch],
  );

  // Set a selected value
  const handleStartDate = useCallback(
    (value: string) => {
      dispatch(setProposalStartValidity(value));
    },
    [dispatch],
  );

  // Init proposal
  useEffect(() => {
    (async () => {
      // Set product select by default if not yet selected
      if (!proposal.productSelect)
        handleProductSelect(subscriptions[0].value as ProductSelect);
      else handleProductCode(proposal.productSelect);

      // Fetch origin stations
      if (proposal.origin) {
        await dispatch(getStationsList());
        if (proposal.productSelect) {
          dispatch(
            getDestinationStationsList({
              origin: proposal.origin.codeStation,
              productSelect: proposal.productSelect,
            }),
          );
        }
      }

      // Manage errors
      if (errorProposal) {
        setIsError(true);
        handleErrorMessage(errorProposal);
      } else if (errorStations) {
        setIsError(true);
        setErrorCode('PROPOSAL_NO_STATIONS');
        scrollToId('alert-error');
      }

      // Init startDateValidity
      if (!startDates.includes(proposal?.startDateValidity ?? '')) {
        handleStartDate(startDates[0]);
      }
    })();
  }, [
    customer.isAuth,
    dispatch,
    errorProposal,
    errorStations,
    handleErrorMessage,
    handleStartDate,
    proposal.destination,
    proposal.origin,
    proposal.productSelect,
    proposal.startDateValidity,
    startDates,
  ]);

  useEffect(() => {
    if (!destinationStations.length || !proposal.destination) return;
    if (
      !destinationStations.find(
        s => s.codeStation === proposal.destination?.codeStation,
      )
    ) {
      inputDestinationRef?.current?.setAttribute('aria-invalid', 'true');
      inputDestinationRef?.current?.setAttribute(
        'aria-describedby',
        'autocomplete-destination-error',
      );
      setDestinationSelectError(
        t('proposal.errors.destination-station-is-not-available'),
      );
      dispatch(cleanProposalDestination());
    } else {
      setDestinationSelectError('');
    }
  }, [destinationStations]);

  // Handle productSelect
  const handleProductSelect = (value?: ProductSelect | null) => {
    // Set the product select
    dispatch(setProposalProductSelect(value));

    // Update proposal productCode
    handleProductCode(value);

    // Get destinations list
    if (proposal.origin && value) {
      dispatch(
        getDestinationStationsList({
          origin: proposal.origin.codeStation,
          productSelect: value,
        }),
      );
    }
  };

  // Update proposal productCode
  const handleProductCode = (value?: ProductSelect | null) => {
    const productCode = subscriptions.find(
      subscription => subscription.value === value,
    )?.productCode;
    if (productCode) {
      dispatch(setProposalProductCode(productCode));
    }
  };

  // First keypress : fetch api and fill store
  const handleStationLoading = () => {
    if (!originStations?.length) dispatch(getStationsList());
  };

  // Receive a selected value
  const handleStationOrigin = (
    event: ChangeEvent<unknown>,
    value?: string | null,
  ) => {
    const station = originStations?.find(s => s.labelStation === value);
    if (station) {
      inputOriginRef?.current?.setAttribute('aria-invalid', 'false');
      setOriginSelectError('');
      if (proposal?.productSelect) {
        dispatch(
          getDestinationStationsList({
            origin: station.codeStation,
            productSelect: proposal.productSelect,
          }),
        );
      }
    } else {
      dispatch(cleanDestinationStations());
    }
    handleStationDestination(event, null);

    dispatch(
      station
        ? setProposalOriginStation(station)
        : cleanProposalOriginStation(),
    );
  };

  // Receive a selected value
  const handleStationDestination = (
    _event: ChangeEvent<unknown>,
    value?: string | null,
  ) => {
    const station = destinationStations?.find(s => s.labelStation === value);
    if (station) {
      inputDestinationRef?.current?.setAttribute('aria-invalid', 'false');
      setDestinationSelectError('');
    }
    dispatch(
      station
        ? setProposalDestinationStation(station)
        : cleanProposalDestinationStation(),
    );
  };

  // Handle Proposal Quote Sales
  const handleProposalQuote = async (
    inputOriginRef: RefObject<HTMLInputElement>,
    inputDestinationRef: RefObject<HTMLInputElement>,
  ) => {
    if (!proposal.origin) {
      inputOriginRef?.current?.setAttribute('aria-invalid', 'true');
      inputOriginRef?.current?.setAttribute(
        'aria-describedby',
        'autocomplete-origin-error',
      );
      inputOriginRef?.current?.focus();
      return setOriginSelectError(t('proposal.errors.origin-station-required'));
    }
    if (!proposal.destination) {
      inputDestinationRef?.current?.setAttribute('aria-invalid', 'true');
      inputDestinationRef?.current?.setAttribute(
        'aria-describedby',
        'autocomplete-destination-error',
      );
      inputDestinationRef?.current?.focus();
      return setDestinationSelectError(
        t('proposal.errors.destination-station-required'),
      );
    }

    dispatch(cleanBasketBookingSale());

    // Query proposal informations
    const proposalCopy = { ...proposal };
    delete proposalCopy.travelClass;
    dispatch(
      getProposalQuoteSales({
        proposal: proposalCopy,
        startDateValidity: proposal.startDateValidity as string,
        productCode: proposal.productCode,
        purchaseType: proposal.purchaseType,
        isKnown: customer?.isAuth && allowedCards.length > 0,
        ...(customer.isAuth && {
          birthDate: formatApiDateUTC(customer?.customer?.birthDate),
        }),
      }),
    );
  };

  const ItemSelectStartDate = () => {
    const startDate = proposal.startDateValidity ?? startDates[0];
    const dateLabel = t('proposal.my-subscription-summary.validity-start');

    return (
      <>
        <Grid xs={12} md={3} item>
          <label
            id="autocomplete-startDate-label"
            aria-label={`${dateLabel} ${formatYYYYMMDDMonthNameAndYearTz(
              startDate,
            )}`}
          >
            <Typography variant="subtitle2" role="none">
              {dateLabel}
            </Typography>
          </label>
        </Grid>
        <Grid xs={12} md={9} item>
          {proposal.destination &&
          proposal.origin &&
          !originStations?.length &&
          !destinationStations?.length ? (
            <Skeleton height={50} animation="wave" />
          ) : (
            <FormControl variant="outlined" fullWidth>
              <Select
                value={startDate}
                onChange={e => handleStartDate(e.target.value as string)}
                labelId="autocomplete-startDate-label"
                className={classes.inputSelect}
                data-test-id="widget-proposal-startDate"
              >
                {startDates.map((date, index) => (
                  <MenuItem
                    value={date}
                    id={`autocomplete-startDate-option-${index}`}
                    key={index}
                  >
                    {formatYYYYMMDDMonthNameAndYearTz(date)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
      </>
    );
  };

  const inputOriginRef = useRef<HTMLInputElement>(null),
    inputDestinationRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Panel>
        <PanelContent>
          <Grid
            container
            alignItems="center"
            spacing={2}
            alignContent="flex-start"
            className={classes.root}
          >
            {/* Error Reporting */}
            {isError && (
              <Grid xs={12} item>
                <AlertMessage
                  error={{ errorCode }}
                  id="alert-error"
                  onClose={() => setIsError(false)}
                />
              </Grid>
            )}
            {/* Choose Plan */}
            <Grid xs={12} md={3} item>
              <Typography
                id="proposal-subscription-label"
                variant="subtitle2"
                role="none"
              >
                <b>{t('proposal.subscription')}</b>
              </Typography>
            </Grid>
            <Grid xs={12} md={9} item>
              <RadioButtonGroup
                options={subscriptions}
                value={proposal.productSelect}
                onChange={handleProductSelect}
                ariaLabel={t('proposal.select-proposal')}
                data-test-id="product-subscription"
              />
            </Grid>

            {/* Choose Origin Station */}
            <Grid xs={12} md={3} item>
              <label htmlFor="autocomplete-origin">
                <Typography
                  id="origin-station-label"
                  variant="subtitle2"
                  role="none"
                >
                  {t('proposal.origin-station')}
                </Typography>
              </label>
            </Grid>
            <Grid xs={12} md={9} item>
              {proposal.origin && !originStations?.length ? (
                <Skeleton height={50} animation="wave" />
              ) : (
                <InputAutocomplete
                  id="autocomplete-origin"
                  inputRef={inputOriginRef}
                  ariaLabel="origin-station-label"
                  clearText={t('proposal.clear-origin-station')}
                  openText={t('proposal.open-origin-station')}
                  closeText={t('proposal.close-origin-station')}
                  options={originStations
                    ?.filter(
                      s =>
                        !s.equivOnly &&
                        s.codeStation !== proposal.destination?.codeStation,
                    )
                    .map(s => s.labelStation)}
                  defaultValue={
                    originStations?.find(
                      s => s.codeStation === proposal.origin?.codeStation,
                    )?.labelStation
                  }
                  onChange={handleStationOrigin}
                  value={(proposal.origin?.labelStation as string) ?? undefined}
                  onOpen={handleStationLoading}
                  loading={!originStations?.length}
                  helperText={originSelectError}
                  error={!!originSelectError}
                  errorId="wi-origin-error"
                />
              )}
            </Grid>

            {/* Choose Destination Station */}
            <Grid xs={12} md={3} item>
              <label htmlFor="autocomplete-destination">
                <Typography
                  id="destination-station-label"
                  variant="subtitle2"
                  role="none"
                >
                  {t('proposal.destination-station')}
                </Typography>
              </label>
            </Grid>
            <Grid xs={12} md={9} item>
              {proposal.origin && !originStations?.length ? (
                <Skeleton height={50} animation="wave" />
              ) : (
                <InputAutocomplete
                  id="autocomplete-destination"
                  inputRef={inputDestinationRef}
                  ariaLabel="destination-station-label"
                  clearText={t('proposal.clear-destination-station')}
                  openText={t('proposal.open-destination-station')}
                  closeText={t('proposal.close-destination-station')}
                  options={destinationStations
                    .filter(
                      s =>
                        !s.equivOnly &&
                        s.codeStation !== proposal.origin?.codeStation,
                    )
                    .map(s => s.labelStation)}
                  defaultValue={
                    destinationStations.find(
                      s => s.codeStation === proposal.destination?.codeStation,
                    )?.labelStation
                  }
                  onChange={handleStationDestination}
                  onOpen={handleStationLoading}
                  loading={!destinationStations}
                  value={
                    (proposal.destination?.labelStation as string) ?? undefined
                  }
                  helperText={destinationSelectError}
                  error={!!destinationSelectError}
                  errorId="autocomplete-destination-error"
                />
              )}
            </Grid>

            <ItemSelectStartDate />

            {/* Show all stations list in a modal */}

            {proposal.origin && proposal.destination && (
              <>
                <Grid xs={12} md={3} item />
                <Grid xs={12} md={9} item>
                  <RouteLink
                    to="#"
                    onClick={e => {
                      e.preventDefault();
                      setShowModalShowEligibleStations(true);
                    }}
                  >
                    <Typography className={classes.link}>
                      {t('proposal.know-all-stations.text')}
                    </Typography>
                  </RouteLink>
                </Grid>
              </>
            )}
          </Grid>
        </PanelContent>

        <PanelActionButtons>
          <ActionButton
            onClick={() =>
              handleProposalQuote(inputOriginRef, inputDestinationRef)
            }
            loading={proposal.loading}
          >
            {t('proposal.action.calculate')}
          </ActionButton>
        </PanelActionButtons>
      </Panel>
      <ModalShowElligibleStations
        open={showModalShowEligibleStations}
        onClose={() => setShowModalShowEligibleStations(false)}
      />
    </>
  );
};
