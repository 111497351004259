import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import { tss } from 'tss-react/mui';

import {
  ActionButton,
  Panel,
  PanelActionButtons,
  PanelContent,
} from '@sticky/components';
import { t, Trans } from '@sticky/i18n';
import type { Field } from '@sticky/sticky-common';
import {
  AccountBlockedModal,
  AccountConnectModal,
  AlertMessage,
  BlacklistControlProblemModal,
  cleanProposalPlansAndPlanId,
  customerSelectors,
  DataTable,
  formatApiDateUTC,
  FraudControlModal,
  getProposalQuoteSales,
  getStationsList,
  proposalSelectors,
  RadioCardPlan,
  scrollToId,
  setProposalTravelClass,
} from '@sticky/sticky-common';
import { BlacklistControlService } from '@sticky/sticky-common/src/features/customer/services/blacklist-control.service';
import { cleanCustomerLogAfterQuote } from '@sticky/sticky-common/src/features/customer/store/customer-slice';
import {
  useAppDispatch,
  useAppSelector,
} from '@sticky/sticky-common/src/hooks';
import { Routes } from '@sticky/sticky-common/src/routes/routes';

const useStyles = tss.create(({ theme }) => ({
  alertMessage: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

export const WidgetProposalChoosePlan = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [displayAccountModal, setDisplayAccountModal] = useState(false);
  const [showBlockedAccountCard, setShowBlockedAccountCard] = useState(false);
  const [checkingControls, setCheckingControls] = useState(false);
  const [
    showBlacklistControlProblemModal,
    setShowBlacklistControlProblemModal,
  ] = useState(false);
  const [showFraudControlModal, setShowFraudControlModal] = useState(false);

  // Get stores and refs
  const proposal = useAppSelector(state => state.proposal);
  const proposalLabel = useAppSelector(proposalSelectors.getProposalLabel);
  const customer = useAppSelector(state => state.customer);
  const allowedCards = useAppSelector(customerSelectors.getAllowedCards);
  const firstPlanRef = useRef<HTMLElement>(null);
  const otherPlanRef = useRef<HTMLElement>(null);
  const travelClasses: { value: string; label: string; title: string }[] = t(
    'proposal.travelClass',
    { returnObjects: true },
  );

  // Manage error
  const [errorCode, setErrorCode] = useState('');

  // Check controls
  const checkControlsAndGoNext = useCallback(async (): Promise<void> => {
    setCheckingControls(true);
    return BlacklistControlService.throwIfRejected()
      .then(() => navigate(Routes.getPathById('enrollment/choose-plan')))
      .catch(err => {
        if (err.message === 'BLACKLISTED') {
          setShowBlockedAccountCard(true);
        } else if (err.message === 'FRAUD') {
          setShowFraudControlModal(true);
        } else {
          setShowBlacklistControlProblemModal(true);
        }
        setCheckingControls(false);
      });
  }, [navigate]);

  const nextStep = useCallback(async (): Promise<void> => {
    // Turn off display modal loading state
    setDisplayAccountModal(false);

    // If the user has already an NF or FF card + The selected subscription is for a FULL TIME
    if (allowedCards.length) {
      const proposalCopy = { ...proposal };
      delete proposalCopy.travelClass;
      dispatch(
        getProposalQuoteSales({
          proposal: proposalCopy,
          startDateValidity: proposal.startDateValidity as string,
          productCode: proposal.productCode,
          purchaseType: proposal.purchaseType,
          birthDate: formatApiDateUTC(customer?.customer?.birthDate),
          isKnown: true,
        }),
      );
    }
    dispatch(cleanCustomerLogAfterQuote());

    // Launch backend controls in parallel before going further
    return checkControlsAndGoNext();
  }, [
    checkControlsAndGoNext,
    customer?.customer?.birthDate,
    customer?.customer?.cards,
    dispatch,
    proposal,
  ]);

  // Load stations
  useEffect(() => {
    dispatch(getStationsList());
  }, [dispatch]);

  // When customer just passed the account modal
  useEffect(() => {
    if (customer?.logAfterQuote && customer?.customer) {
      nextStep();
    }
  }, [nextStep, customer]);

  // Accessibility
  useEffect(() => {
    otherPlanRef?.current?.hasAttribute('checked')
      ? otherPlanRef?.current?.focus()
      : firstPlanRef?.current?.focus();
  }, [dispatch]);

  // Handle travelClass change
  const handlePlanSelection = (value: string) => {
    dispatch(setProposalTravelClass(value));
    setErrorCode('');
  };

  // Show error message
  const showError = (_errorMessage: string) => {
    setErrorCode(_errorMessage);
    if (_errorMessage) scrollToId('alert-message');
  };

  // Handle subscribe
  const handleSubscribe = async () => {
    const plan = proposal?.plans?.find(
      s => s.travelClass === proposal.travelClass,
    );
    if (!plan) {
      showError('PROPOSAL_PLAN_SELECTION_REQUIRED');
      return;
    }
    if (customer.isAuth) {
      return checkControlsAndGoNext();
    } else {
      setDisplayAccountModal(true);
    }
  };

  // Prepare fields
  const fields: Field[] = [
    ['proposal.type-of-plan', proposalLabel],
    [
      'proposal.journey',
      `${proposal.origin?.labelStation} - ${proposal.destination?.labelStation}`,
    ],
  ];

  return (
    <Panel>
      <PanelContent>
        <AccountConnectModal
          open={displayAccountModal && !customer.isAuth}
          onclose={() => setDisplayAccountModal(false)}
        />
        <AccountBlockedModal
          open={showBlockedAccountCard}
          onClose={() => setShowBlockedAccountCard(false)}
        />
        <FraudControlModal
          open={showFraudControlModal}
          onClose={() => setShowFraudControlModal(false)}
        />
        <BlacklistControlProblemModal
          open={showBlacklistControlProblemModal}
          onClose={() => setShowBlacklistControlProblemModal(false)}
        />

        <Stack alignItems="center" gap={2}>
          <DataTable fields={fields} isReady={true} />

          {errorCode && (
            <AlertMessage
              error={{ errorCode }}
              id="alert-message"
              className={classes.alertMessage}
            />
          )}

          <Stack direction="row" gap={4} width="100%">
            {proposal.plans &&
              ['2', '1'].map((travelClass: string, index: number) => {
                const plans = proposal?.plans?.filter(
                  p => p.travelClass === travelClass,
                );
                const travelClassTrad = travelClasses.find(
                  s => s.value === travelClass,
                );

                return (
                  <Box key={index} flex={1}>
                    <RadioCardPlan
                      inputRef={index === 0 ? firstPlanRef : otherPlanRef}
                      value={travelClass}
                      selected={proposal.travelClass}
                      onChange={() => handlePlanSelection(travelClass)}
                      title={
                        <Typography
                          variant="h2"
                          align="center"
                          component="div"
                          role="none"
                        >
                          <Trans components={[<sup key="0" />]}>
                            {travelClassTrad?.title}
                          </Trans>
                        </Typography>
                      }
                      tabIndex={0}
                      label={travelClassTrad?.label}
                      pricePerMonth={
                        plans?.filter(p => !p.cashPayment)[0]?.amount
                      }
                      pricePerYear={
                        plans?.filter(p => p.cashPayment)[0]?.amount
                      }
                    />
                  </Box>
                );
              })}
          </Stack>
        </Stack>
      </PanelContent>

      <PanelActionButtons>
        <ActionButton
          tabIndex={0}
          onClick={() => handleSubscribe()}
          loading={displayAccountModal || checkingControls}
        >
          {t('actions.subscribe')}
        </ActionButton>

        <ActionButton
          variant="outlined"
          onClick={() => dispatch(cleanProposalPlansAndPlanId())}
          tabIndex={0}
        >
          {t('actions.change')}
        </ActionButton>
      </PanelActionButtons>
    </Panel>
  );
};
