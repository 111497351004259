import { useAppSelector } from '@sticky/sticky-common/src/hooks';

import { WidgetProposalChoosePlan } from './widget-proposal-choose-plan';
import { WidgetProposalInit } from './widget-proposal-init';

export const WidgetProposal = () => {
  const proposal = useAppSelector(state => state.proposal);
  const isFilled =
    proposal?.origin &&
    proposal.destination &&
    proposal.productSelect &&
    proposal.startDateValidity &&
    proposal.plans;
  return !isFilled ? <WidgetProposalInit /> : <WidgetProposalChoosePlan />;
};
