import type { IHomeBanner } from '@sticky/sticky-common/src/resources/types/banner/homeBanner';

const translations: IHomeBanner[] = [
  {
    title: 'Informations abonné(e)s',
    mainMessage:
      'Nous vous invitons à anticiper la réservation de vos billets MAX ACTIF et MAX ACTIF+ pour la période des Jeux Paralympiques de Paris qui se dérouleront du 28 août au 8 septembre.',
    secondMessage:
      ' Les réservations sur votre plateforme dédiée seront ouvertes à partir de 60 jours avant le départ.',
  },
];

export default translations;
