import { configureFeatures } from '@sticky/config';

configureFeatures({
  dev: {
    subscription: {
      linkWithJourney: true,
      activationCanBeDelayed: true,
      billTo: {
        canBeACompany: true,
      },
      displayQRCode: true,
    },
    enrollment: {
      canGoBackToProposal: true,
      payment: {
        canUseCB: true,
      },
      id360: {
        help: true,
      },
      gpg: {
        sendSms: false,
        aisHelp: true,
      },
    },
    strapi: {
      enabled: true,
    },
    customer: {
      invoice: {
        regularizeUnpayment: true,
      },
      reservation: {
        suspensionActions: true,
      },
    },
    reservation: {
      multipleSelection: true,
      withCalendar: true,
    },
    brands: {
      feria: true,
      kombo: true,
    },
    terms: {
      validation: true,
    },
    banners: {
      activeOnHomePage: true,
      showMoreButton: false,
    },
    popIn: {
      homeInformationPopIn: true,
    },
    tracker: {
      didomi: {
        enabled: true,
      },
      datadog: {
        enabled: true,
        traceSampleRate: 100,
        sessionSampleRate: 100,
        trackUserInteractions: true,
        sessionReplaySampleRate: 0,
      },
    },
  },

  prod: {
    subscription: {
      linkWithJourney: true,
      activationCanBeDelayed: true,
      billTo: {
        canBeACompany: true,
      },
      displayQRCode: true,
    },
    enrollment: {
      canGoBackToProposal: true,
      payment: {
        canUseCB: true,
      },
      id360: {
        help: true,
      },
      gpg: {
        sendSms: true,
        aisHelp: true,
      },
    },
    strapi: {
      enabled: true,
    },
    customer: {
      invoice: {
        regularizeUnpayment: true,
      },
      reservation: {
        suspensionActions: true,
      },
    },
    reservation: {
      withCalendar: true,
    },
    brands: {
      feria: true,
      kombo: true,
    },
    banners: {
      activeOnHomePage: true,
      showMoreButton: false,
    },
    popIn: {
      homeInformationPopIn: false,
    },
    tracker: {
      didomi: {
        enabled: true,
      },
      datadog: {
        enabled: true,
        traceSampleRate: 10,
        sessionSampleRate: 10,
        trackUserInteractions: true,
        sessionReplaySampleRate: 10,
      },
    },
  },
});
