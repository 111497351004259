import { Typography } from '@mui/material';
import { tss } from 'tss-react/mui';

import { t, Trans } from '@sticky/i18n';
import type { IStation } from '@sticky/sticky-common';
import { ModalBox, proposalSelectors } from '@sticky/sticky-common';
import { useAppSelector } from '@sticky/sticky-common/src/hooks';

interface ModalShowElligibleStationsProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = tss.create(({ theme }) => {
  const {
    breakpoints,
    app: { colors },
  } = theme;

  return {
    cardContentDescription: {
      paddingBottom: theme.spacing(3),
      '& strong': {
        fontWeight: 'bold',
      },
    },
    cardContentColumns: {
      display: 'grid',
      gridTemplateAreas: `"origin-station" "destination-station"`,
      rowGap: theme.spacing(3),
      marginBottom: theme.spacing(1),
      [breakpoints.up('breakPointDesktop')]: {
        gridTemplateAreas: `"origin-station destination-station"`,
        gridTemplateColumns: '50% 50%',
      },
    },
    cardContentColumnStation: {
      '& h4': {
        borderBottom: `1px solid ${colors.warmGray1}`,
        paddingBottom: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      '&.origin-title': {
        gridArea: 'origin-station',
        marginRight: theme.spacing(4),
      },
      '&.destination-title': {
        gridArea: 'destination-station',
      },
      '& ul': {
        margin: `${theme.spacing(1)} 0`,
        padding: 0,
        listStyle: 'none',
        display: 'grid',
        gap: theme.spacing(1),
        '& li': {
          display: 'grid',
          gridTemplateColumns: '0 1fr',
          gap: theme.spacing(3.5),
          alignItems: 'start',
          '&::before': {
            content: 'attr(data-icon)',
            fontSize: theme.spacing(2),
            paddingLeft: theme.spacing(1),
            color: colors.primary,
          },
        },
      },
    },
  };
});

// Modal Component
export const ModalShowElligibleStations = (
  props: ModalShowElligibleStationsProps,
) => {
  const { classes } = useStyles();

  // Get stores
  const eligibleStations: IStation[] = useAppSelector(
    state => state.stations?.eligibleStations ?? [],
  );
  const proposal = useAppSelector(state => state.proposal);
  const productSelect = useAppSelector(proposalSelectors.getProposalLabel);

  // Display a list of stations
  const StationsList = ({ type }: { type: string }) => {
    const station = type === 'origin' ? proposal.origin : proposal.destination;
    const stationsList = eligibleStations
      .filter(s =>
        [s?.labelStation, s?.areaStation].includes(
          station?.areaStation || station?.labelStation,
        ),
      )
      .map(s => s.labelStation)
      .sort();

    return (
      <div
        className={[classes.cardContentColumnStation, `${type}-title`].join(
          ' ',
        )}
      >
        <Typography component="h4" variant="h6">
          {t(`proposal.modal-show-all-stations.${type}-title`)}
        </Typography>
        <ul>
          {stationsList.map((s: string, key: number) => (
            <li key={key} data-icon="●">
              <Typography variant="body1">{s}</Typography>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <ModalBox
      open={props.open}
      onClose={props.onClose}
      title={t('proposal.modal-show-all-stations.title').toUpperCase()}
      titleComponent="h3"
    >
      <>
        <Typography variant="body1" className={classes.cardContentDescription}>
          <Trans
            i18nKey="proposal.modal-show-all-stations.description"
            values={{
              productSelect: productSelect,
              originStationLabel: proposal.origin?.labelStation,
              destinationStationLabel: proposal.destination?.labelStation,
            }}
          />
        </Typography>
        <div className={classes.cardContentColumns}>
          <StationsList type="origin"></StationsList>
          <StationsList type="destination"></StationsList>
        </div>
      </>
    </ModalBox>
  );
};
