import { lazy, useEffect, useState } from 'react';
import { Box, Hidden, Typography } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { getFeatures } from '@sticky/config';
import { t } from '@sticky/i18n';
import {
  AuthenticationWidget,
  HomeDescription,
  HomeFaq,
  HomeLogin,
  LayoutHomePage,
  Offers,
  ProductSelect,
  ResumptionModal,
  WhySubscribe,
} from '@sticky/sticky-common';
import type { Offer } from '@sticky/sticky-common/src/components/home/types';
import { getCustomerWithSubscription } from '@sticky/sticky-common/src/features/customer/store/customer-slice';
import { InformationPopInService } from '@sticky/sticky-common/src/features/information-pop-in/information-pop-in-service';
import {
  useAppDispatch,
  useAppSelector,
} from '@sticky/sticky-common/src/hooks';

import { WidgetProposal } from '../../components/proposal/widget-proposal';

const InformationModal = lazy(
  () =>
    import(
      '@sticky/sticky-common/src/components/modal/information-modal/information-modal'
    ),
);

const useStyles = makeStyles()((theme: Theme) => {
  const {
    breakpoints,
    app: { commonSpacing },
  } = theme;
  return {
    cta: {
      display: 'none',
      [breakpoints.up('breakPointDesktop')]: {
        display: 'block',
        marginTop: theme.spacing(3),
      },
    },
    title: {
      fontSize: theme.spacing(3),
      lineHeight: '1.41',
      marginBottom: theme.spacing(1),
    },
    caption: {
      lineHeight: '1.41',
      [breakpoints.up('breakPointDesktop')]: {
        paddingBottom: theme.spacing(1.5),
      },
    },
    spacing: {
      marginTop: theme.spacing(4),
      [breakpoints.up('breakPointDesktop')]: {
        marginTop: theme.spacing(8),
      },
    },
    spacingNegative: {
      [breakpoints.up('breakPointDesktop')]: {
        marginTop: theme.spacing(commonSpacing * -1.5),
      },
    },
    introduction: {
      [breakpoints.up('breakPointDesktop')]: {
        display: 'grid',
        columnGap: theme.spacing(commonSpacing),
        gridTemplateColumns: 'repeat(2, 1fr)',
        height: 193,
      },
    },
    proposal: {
      marginTop: theme.spacing(4),
      [breakpoints.up('breakPointDesktop')]: {
        display: 'grid',
        columnGap: theme.spacing(commonSpacing / 2),
        gridTemplateColumns: 'repeat(3, 1fr)',
        marginTop: theme.spacing(2),
      },
    },
    widgetProposal: {
      alignSelf: 'end',
      [breakpoints.up('breakPointDesktop')]: {
        gridColumn: '2 / 4',
        marginLeft: theme.spacing(commonSpacing / 2),
      },

      [breakpoints.down('breakPointDesktop')]: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
      },
    },
    widgetMyId: {
      display: 'grid',
      alignSelf: 'end',
      gridColumn: '1 / 2',
    },
  };
});

export const Home = (): JSX.Element => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const customer = useAppSelector(state => state.customer);
  const [showInfoModal, setShowInfoModal] = useState(false);

  const offers: Offer[] = [
    {
      isDark: true,
      title: t('home.offersBox.offers.maxActif.title'),
      descriptions: t('home.offersBox.offers.maxActif.descriptions', {
        returnObjects: true,
      }),
      chooseButton: {
        product: ProductSelect.PART_TIME,
        target: 'widget-proposal',
      },
    },
    {
      isBigger: true,
      isDark: false,
      title: t('home.offersBox.offers.maxActifPlus.title'),
      descriptions: t('home.offersBox.offers.maxActifPlus.descriptions', {
        returnObjects: true,
      }),
      chooseButton: {
        product: ProductSelect.FULL_TIME,
        target: 'widget-proposal',
      },
    },
  ];

  useEffect(() => {
    //Should show information pop in
    const isInformationPopInEnabled = getFeatures().popIn.homeInformationPopIn;
    const shouldShowInformationPopIn =
      InformationPopInService.shouldShowInformationPopIn(
        t('informationPopIn.content'),
      );
    setShowInfoModal(isInformationPopInEnabled && shouldShowInformationPopIn);
  }, []);

  useEffect(() => {
    if (!customer) {
      dispatch(getCustomerWithSubscription());
    }
  }, [customer, dispatch]);

  return (
    <LayoutHomePage>
      <ResumptionModal />
      {showInfoModal && (
        <InformationModal
          open={showInfoModal}
          onClose={() => {
            setShowInfoModal(false);
          }}
        />
      )}

      <Box className="main">
        <Box className={classes.introduction}>
          <HomeDescription />
          <Hidden mdDown>
            <HomeLogin />
          </Hidden>
        </Box>

        <Box className={classes.spacingNegative}>
          <Offers offers={offers} />
        </Box>

        <Box className={classes.proposal}>
          <div className={classes.widgetMyId}>
            <AuthenticationWidget />
          </div>
          <div className={classes.widgetProposal} id="widget-proposal">
            <Box className={classes.cta}>
              <Typography
                component="h2"
                variant="subtitle2"
                className={classes.title}
              >
                {t('widgetMyId.title')}
              </Typography>

              <Typography component="p" className={classes.caption}>
                {t('widgetMyId.subtitle')}
              </Typography>
            </Box>
            <WidgetProposal />
          </div>
        </Box>
        <Box className={classes.spacing}>
          <WhySubscribe />
        </Box>
        <Box className={classes.spacing}>
          <HomeFaq />
        </Box>
      </Box>
    </LayoutHomePage>
  );
};
