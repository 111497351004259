import { lazy } from 'react';

import { t } from '@sticky/i18n';
import { LAYOUT } from '@sticky/sticky-common/src/routes/layout';
import type { IRoute } from '@sticky/sticky-common/src/routes/routes';
import { Routes } from '@sticky/sticky-common/src/routes/routes';

import { Home } from './pages/home/home';

export const routes: IRoute[] = [
  {
    id: 'home',
    path: '/',
    element: Home,
    title: t('home.title'),
    layout: LAYOUT.HOME,
  },

  {
    id: 'customer/my-travels',
    path: '/mes-voyages',
    element: lazy(() => import('./pages/customer/customer-travels')),
    title: t('customer.travels.index'),
    layout: LAYOUT.CUSTOMER,
    private: true,
  },
  {
    id: 'customer/reservation/preferences',
    path: '/mes-voyages/reservation/preferences',
    element: lazy(() => import('./pages/reservation/reservation-preferences')),
    title: t('reservation.my-travels.preferences'),
    layout: LAYOUT.CUSTOMER,
    private: true,
  },
  {
    id: 'customer/reservation/search',
    path: '/mes-voyages/reservation/resultats-recherche',
    element: lazy(
      () => import('./pages/reservation/reservation-proposals-list'),
    ),
    title: t('reservation.my-travels.results'),
    layout: LAYOUT.CUSTOMER,
    private: true,
  },
  {
    id: 'customer/exchange/criteria',
    path: '/mes-voyages/echange/criteres',
    element: lazy(
      () => import('./pages/reservation/reservation-exchange-criteria'),
    ),
    title: t('exchange.title'),
    layout: LAYOUT.CUSTOMER,
    private: true,
  },
  {
    id: 'customer/exchange/results',
    path: '/mes-voyages/echange/resultats',
    element: lazy(
      () => import('./pages/reservation/reservation-exchange-results'),
    ),
    title: t('exchange.title'),
    layout: LAYOUT.CUSTOMER,
    private: true,
  },
];

Routes.addRoutes(routes);
