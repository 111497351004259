/**
 * Returns the 1st day of a month.
 *
 * @param  {Date} date - The date to convert in 1st day's month.
 * @returns {Date} - The converted date.
 */
export const getStartOfMonthDate = (date: Date): Date => {
  const [_date] = date.toISOString().split('T');
  const [years, months] = _date.split('-');
  return new Date(`${years}-${months}-01T00:00:00.000Z`);
};

/**
 * Returns the 1st hour of a day.
 *
 * @param  {Date} date - The date to convert in 1st hour's day.
 * @returns {Date} - The converted date.
 */
export const getStartOfDayDate = (date: Date): Date => {
  const [_date] = date.toISOString().split('T');
  return new Date(`${_date}T00:00:00.000Z`);
};

// 0 = sunday, 1 = monday, 2 = thursday, 3 = wednesday, 4 = tuesday, 5 = friday, 6 = saturday
export type WeekDay = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const MAX_ACTIF_DAYS: WeekDay[] = [1, 2, 3, 4];
export const MAX_ACTIF_PLUS_DAYS: WeekDay[] = [0, 1, 2, 3, 4, 5, 6];

/**
 * Returns the week day of a date, with a restricted type.
 * @param date the date
 * @returns the week day
 */
export const getDay = (date: Date): WeekDay => date.getDay() as WeekDay;
